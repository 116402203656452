<template>
  <v-dialog
    max-width="300"
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    persistent
  >
    <v-card class="box-shadow-light pa-4">
      <div class="d-flex flex-column flex-grow-1">
        <div class="d-flex flex-column flex-grow-1 align-center">
          <!-- <h3
            class=" ft font-weight-bold font-italic primary--text"
            style="font-size: 50px; text-shadow: 2px 2px 2px #2f2f44;"
          >
            P
          </h3> -->
          <img
            src="@/assets/android-icon-192x192.png"
            style="width: 90px;height: 100px;"
            class=" pa-3"
          />
          <span class="ft font-weight-bold font-size-lg pt-1 pb-3"
            >Log out of Shalom CRM?</span
          >
          <span
            class="ft text-center font-weight-medium font-size-md"
            style="line-height: 1.5"
            >You can always log back into your accounts anytime.</span
          >
        </div>
        <div class="d-flex flex-row flex-grow-1 pt-5 pb-3 justify-center">
          <v-btn
            depressed
            @click="close"
            color="grey lighten-2"
            rounded
            class="mx-2"
          >
            <span class="ft font-weight-bold text-capitalize">Cancel</span>
          </v-btn>
          <v-btn @click="logOut" depressed color="primary" rounded class="mx-2">
            <span class="ft font-weight-bold text-capitalize ">Logout</span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "LogoutDialog",
  props: {
    state: Boolean
  },
  data: () => ({}),
  methods: {
    close() {
      this.$emit("close", "logout");
    },
    logOut() {
      this.$emit("logout", "logout");
    }
  }
};
</script>
